/* eslint no-unused-vars: ["error", { "varsIgnorePattern": "pushMenu" }] */

import pushMenu from 'is-mobile-nav';

export default () => {

  if ($('.site-header').length) {

    $('.mobile-header-search').on('click', function() {
      $(this).addClass('-active');
    });

    $('.mobile-header-search, .CoveoSearchButton, .magic-box-suggestions, .coveo-magicbox-suggestions').on('click', function(e) {
      e.stopPropagation();
    });

    $('.mobile-header-search-close').on('click', function(e) {
      e.stopPropagation();
      e.preventDefault();
      $('.mobile-header-search').removeClass('-active');
    });

    $(document).on('click', function() {
      $('.mobile-header-search').removeClass('-active');
    });


    if ($('.js-mobile-trigger').length) {

      $('.js-mobile-trigger').on('click', (e) => {
        e.preventDefault();
      });

      $('.js-mobile-menu-inner').pushMenu({
        menu: '.mobile-nav-source',
        mobileNav: '.js-mobile-menu',
        navButton: '.js-mobile-trigger',
        pageContainer: '.page-container',
        scrollBlocker: '.blocker',
        nextHTML: '<a href="#" class="subopen" data-direction="next">Next</a>',
        prevHTML: '<li class="nav-back"><a class="subclose" data-direction="prev" href="#">Back</a></li>'
      });

      $('.js-mobile-menu-inner').on('click', '.js-mobile-nav-inner-close > a', (e) => {
        e.preventDefault();
        $('html').removeClass('-js-menu-open');
      });

      // add the close (back arrow) button for 100% width version
      $('.js-mobile-menu-inner ul[data-position="0"]')
        .prepend('<li class="js-mobile-nav-inner-close"><a href="#">Close</a></li>')
      ;

      if ($('.site-header-my-acog.-logged-out').css('display') === 'none') {
        $('.my-acog-links-dropdown-container .my-acog-login-button')
          .clone(true)
          .appendTo('.js-mobile-menu-inner .js-mobile-nav-inner-close')
          .wrap('<span class="js-mobile-nav-login mobile-nav-login-cloned">')
          .addClass('my-acog-login-button-mobile')
        ;
      }

      if ($('.site-header-my-acog.-logged-in').css('display') === 'none') {
        $('.site-header-my-acog.-logged-out')
          .clone(true)
          .appendTo('.js-mobile-menu-inner .js-mobile-nav-inner-close')
          .wrap('<span class="js-mobile-nav-login mobile-nav-login-cloned">')
          .addClass('my-acog-login-buttons-mobile')
        ;
      }

      // append secondary nav links
      $('.site-header-secondary-nav')
        .clone(true)
        .appendTo('.js-mobile-menu-inner ul[data-position="0"]')
        .wrap('<li class="js-mobile-secondary-nav">')
        .removeClass('site-header-secondary-nav')
        .addClass('js-mobile-secondary-nav-links')
      ;

      // append utility nav links
      $('.site-header-utility-nav > ul')
        .clone(true)
        .appendTo('.js-mobile-menu-inner ul[data-position="0"]')
        .wrap('<li class="js-mobile-utility-nav">')
        .addClass('js-mobile-utility-nav-links')
      ;

    }

  }

};
