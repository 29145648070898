export default () => {

  // jump menus
  // if external link open in new window
  if ($('.jump-menu').length) {

    $('.jump-menu').on('change', function() {
      location.href = $(this).val();
    });

  }

};
