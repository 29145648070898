export default () => {

  if ($('.social-share').length) {

    $('.social-share a:not([data-service="email"])').on('click', (e) => {
      e.preventDefault();
      let url;
      const currPage = window.location.href;

      switch ($(e.currentTarget).data('service')) {
      case 'facebook':
        url = 'https://www.facebook.com/sharer/sharer.php?u=' + currPage;
        windowPopup(url);
        break;
      case 'twitter':
        url = 'https://twitter.com/intent/tweet/?url=' + currPage;
        windowPopup(url);
        break;
      case 'linkedin':
        url = 'https://www.linkedin.com/shareArticle?mini=true&url=' + currPage;
        windowPopup(url);
        break;
      case 'link':
        copyToClipboard(currPage);
        break;
      case 'print':
        printPage(currPage);
        break;
      }

    });

    $('.copy-message-close').on('click', (e) => {
      e.preventDefault();
      $('.copy-message').removeClass('-active');
    });

  }

  function windowPopup(url) {
    const top = (screen.height / 3) - 150;
    const left = (screen.width / 2) - 250;

    // Calculate the position of the popup so it’s centered on the screen.
    window.open(
      url,
      '',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=500,height=300,top=' + top + ',left=' + left
    );
  }

  function printPage() {
    window.print();
  }

  function copyToClipboard(currPage) {
    const tempCopyForm = $('<form novalidate><input id="temp-copy-to-clipboard" value="' + currPage + '"></form>');
    $('body').append(tempCopyForm);
    $('#temp-copy-to-clipboard').select();
    document.execCommand('copy');
    $(tempCopyForm).remove();
    $('.copy-message').addClass('-active');
  }

};
