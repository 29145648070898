import tippy from 'tippy.js';

export default () => {

  if ($('.tooltip').length) {

    tippy('.tooltip', {
      distance: 15,
      animation: 'shift-away',
      duration: [275, 250],
      delay: [0, 20],
      arrow: false,
      interactive: true,
      maxWidth: 400,
      theme: 'acog'
    });

  }


  if ($('.tooltip-login').length) {

    const loginTooltipTemplate = document.getElementById('login-tooltip');
    const loginTooltip = document.createElement('div');
    loginTooltip.appendChild(document.importNode(loginTooltipTemplate.content, true));

    tippy('.tooltip-login', {
      content: loginTooltip.innerHTML,
      distance: 15,
      animation: 'shift-away',
      duration: [275, 250],
      delay: [0, 20],
      arrow: false,
      interactive: true,
      maxWidth: 400,
      theme: 'acog'
    });

  }

};
