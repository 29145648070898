/* eslint-disable no-unused-vars, no-console */
import magnificPopup from 'magnific-popup';

export default () => {
  if ($('.bulk-modal').length) {

    $('.bulk-modal-open').on('click', (e) => {
      e.preventDefault();
      $('.bulk-modal').css('display', 'flex');
    });

    $('.bulk-modal-close').on('click', (e) => {
      e.preventDefault();
      $('.bulk-modal').css('display', 'none');
      $.magnificPopup.close();
    });

    $('.bulk-modal-bg').on('click', () => {
      $('.bulk-modal').css('display', 'none');
      $.magnificPopup.close();
    });
  }
};
