import throttle from '../../../js-plugins/throttle';
import imagesLoaded from 'imagesloaded';

export default () => {

  const subnavStickyClinicalEl = $('.-js-subnav-sticky-clinical');
  const subnavStickyHeight = subnavStickyClinicalEl.outerHeight();

  if (subnavStickyClinicalEl.length) {

    const stopPoint = getTop();
    const subnavSticky = subnavStickyClinicalEl.isSticky({
      zIndex: 99,
      stopper: stopPoint,
      offset: 95
    });
    subnavSticky.init();

    const stickyPoint = subnavStickyClinicalEl.offset().top - subnavSticky.offset;

    imagesLoaded.makeJQueryPlugin($);
    $('body').imagesLoaded(() => {
      subnavSticky.setTop(getTop());
      toggleSubnavSticky(subnavSticky);
    });

    const threshold = 1200;
    let initialDiff = ($(window).outerWidth() > threshold) ? 1 : -1;

    $(window).on('resize', throttle(() => {

      subnavSticky.setTop(getTop());

      const w = $(window).outerWidth();
      const currentDiff = w - threshold;
      if (currentDiff * initialDiff < 0) {
        initialDiff *= -1;
        toggleSubnavSticky(subnavSticky);
        subnavSticky.resizeUpdateStickyPoint(stickyPoint);
      }
    }, 500));
  }

  function toggleSubnavSticky(e) {

    if ($(window).outerWidth() >= 1200) {
      e.init();
    } else {
      e.destroy();
    }
  }

  function getTop() {
    return ($('.site-footer').offset().top - subnavStickyHeight) - 90;
  }

};
