export default () => {

  if ($('.hero-notification-trigger').length) {

    $('.hero-notification-trigger').on('click', (e) => {
      e.preventDefault();
      $(e.currentTarget).toggleClass('-active');
      $('.hero-notifaction-dropdown').slideToggle(300).toggleClass('-active');
    });

    $('.hero-notifications').on('click', (e) => {
      e.stopPropagation();
    });

    $(document).on('click', () => {
      if (!$('.hero-notifaction-dropdown').is(':hidden')) {
        $('.hero-notifaction-dropdown').slideUp(300).removeClass('-active');
        $('.hero-notification-trigger').removeClass('-active');
      }
    });

  }

};
