export default () => {

  if ($('.subnav').length) {
    $('.subnav-mobile-trigger').on('click', (e) => {
      e.preventDefault();

      $(e.target)
        .next('.subnav-menu-container')
        .slideToggle()
        .parent('.subnav')
        .toggleClass('-active');

    });
  }

};
