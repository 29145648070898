export default () => {

  // replace ol attributes added by rich text editor
  if ($('.wysiwyg-content ol').length) {

    $('.wysiwyg-content ol').each(function() {

      // remove style attr like style="list-style-type: lower-alpha;" added by the user in text editor
      if ($(this).attr('style')) {
        $(this).removeAttr('style');

      // remove start position and replace with counter reset
      } else if ($(this).attr('start')) {
        const startPos = $(this).attr('start') - 1;
        $(this).removeAttr('start').attr('style', 'counter-reset: li ' + startPos + ';');
      }

    });

  }

};
