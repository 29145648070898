import throttle from '../../../js-plugins/throttle';

export default () => {

  if ($('.promo-bar-sticky').length) {

    const threshold = 1000;
    const stickyHeader = $('.sticky-search-header');
    let stickyOffset = stickyHeader.length && $(window).outerWidth() >= threshold ? stickyHeader.outerHeight() : 0;
    const promoBarStickyEl = $('.promo-bar-sticky');

    const promoBarSticky = promoBarStickyEl.isSticky({
      zIndex: 99,
      stopper: '.site-footer',
      offset: stickyOffset
    });

    promoBarSticky.init();

    $(window).on('resize', throttle(() => {

      stickyOffset = stickyHeader.length && $(window).outerWidth() >= threshold ? stickyHeader.outerHeight() : 0;

      if ($(window).outerWidth() >= threshold) {
        promoBarSticky.updateOffset(stickyOffset);
      } else {
        promoBarSticky.updateOffset(0);
      }

    }, 500));
  }

};
