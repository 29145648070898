/* eslint-disable */

const Search = class Search {
  /**
   * Primary constructor for the Search class.
   *
   * @class Pagination
   * @constructor
   * @param { Config } config
   */
  constructor(config) {
    const { endpoint, keywordParam, searchURL, defaultQueryParams } = config;

    this._isLoading          = false;
    this._defaultQueryParams = defaultQueryParams;
    this._queryParams        = [];
    this._query              = {};
    this._title              = 'Search';
    this._endpoint           = endpoint;
    this._keywordParam       = keywordParam;
    this._searchURL          = searchURL;
    this._fetchOptions       = { method: 'GET' };
  }

  /**
   * Get the default query parameters that are set when the Class is instantiated.
   *
   * @property defaultQueryParams
   * @return { String }
   */
  get defaultQueryParams() {
    return this._defaultQueryParams;
  }

  /**
   * Get & Set the API endpoint being used.
   *
   * @property endpoint
   * @return { String }
   */
  get endpoint() {
    return this._endpoint;
  }

  set endpoint(endpoint) {
    this._endpoint = endpoint;
  }

  /**
   * Get & Set Fetch options that are sent the the Fetch command.
   *
   * @property fetchOptions
   * @return { Object }
   */
  get fetchOptions() {
    return this._fetchOptions;
  }

  set fetchOptions(options) {
    this._fetchOptions = options;
  }

  /**
   * Get the specified keyword parameter.
   *
   * @property fetchOptions
   * @return { Object }
   */
  get keywordParam() {
    return this._keywordParam;
  }

  /**
   * Get & Set if the Fetch request either processing or sent a response.
   *
   * @property isLoading
   * @return { Boolean }
   */
  get isLoading() {
    return this._isLoading;
  }

  set isLoading(state) {
    this._isLoading = state;
  }

  /**
   * Gets & Set the meta response from the API.
   *
   * @property meta
   * @return { Object }
   */
  get meta() {
    return this._meta;
  }

  set meta(meta) {
    this._meta = meta;
  }

  /**
   * Get & Set the default query parameters that are used by default.
   *
   * @property query
   * @return { Object }
   */
  get query() {
    return this._query;
  }

  set query(options) {
    this._query = options;
  }

  /**
   * Get & Set the query parameter being used.
   *
   * @property queryParams
   * @return { OBject }
   */
  get queryParams() {
    return this._queryParams;
  }

  set queryParams(params) {
    this._queryParams = params;
  }

  /**
   * Get & Set the UI URL being used. This is the page being displayed to the
   * user.
   *
   * @property searchURL
   * @return { String }
   */
  get searchURL() {
    return this._searchURL;
  }

  set searchURL(url) {
    this._searchURL = url;
  }

  /**
   * Get & Set all available templates.
   *
   * @property templates
   * @return { Object }
   */
  get templates() {
    return this._templates;
  }

  set templates(templates) {
    this._templates = templates;
  }

  /**
   * Get & Set the HTML5 History API page title being used.
   *
   * @property title
   * @return { String }
   */
  get title() {
    return this._title;
  }

  set title(title) {
    this._title = title;
  }

  /**
   * Formats the available query params for the endpoint. Keep in mind that
   * there currently isn't any endpoint encoding applied.
   *
   * @private
   * @property _buildQueryParams
   * @return { String }
   */
  get _buildQueryParams() {
    let options = [],
        query   = this.query;
    this.queryParams.map(function(key) {
      if (this._isEmptyQuery(key, query[key])) { return; }
      options.push(key + '=' + query[key]);
    }.bind(this));

    return options.join('&');
  }

  /**
   * Returns a new enumerable that excludes the passed value. The default
   * implementation returns an array regardless of the receiver type.
   * If the receiver does not contain the value it returns the original array.
   *
   * ```javascript
   * let arr = ['a', 'b', 'a', 'c'];
   * arr.compact('a');  // ['b', 'c']
   * ```
   *
   * @method compact
   * @param { array } itms
   * @return { Array }
   * @public
   */
  compact(items) {
    return items.filter(value => value != null);
  }

  /**
   * Allow for debouncing.
   *
   * @method debounce
   * @param { Function } func
   * @param { Number } wait
   * @param { Boolean } immediate
   * @return { Method }
   */
  debounce(func, wait, immediate) {
    let timeout;
    return function() {
      let context = this,
          args    = arguments,
          later   = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
          };

      let callNow = immediate && !timeout;
      clearTimeout(timeout);

      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  /**
   * Init function to start the initial fetch request on page load.
   *
   * @method init
   * @return { Promise }
   */
  init() {
    const { defaultQueryParams, query, queryParams } = this,
          defaults = Object.keys(defaultQueryParams);

    let options = defaults.concat(queryParams),
        compact = this.compact(options),
        uniqe   = this.uniq(compact);

    this.queryParams = uniqe;
    this.query       = this._initQuery();

    return this._fetchRequest();
  }

  /**
   * Returns a new enumerable that contains only unique values. The default
   * implementation returns an array regardless of the receiver type.
   *
   * ```javascript
   * let arr = ['a', 'a', 'b', 'b'];
   * arr.uniq();  // ['a', 'b']
   * ```
   *
   * This only works on primitive data types, e.g. Strings, Numbers, etc.
   * @method uniq
   * @return { Array } items
   * @public
  */
  uniq(items) {
    let ret = [];
    items.forEach(k => {
      if (ret.indexOf(k) < 0) {
        ret.push(k);
      }
    });

    return ret;
  }

  /**
   * Processes requested template.
   *
   * @method template
   * @param { String } templateName
   * @param { Object } options
   * @return { HTML }
   */
  template(templateName, options) {
    let template = this.templates[templateName];
    options.map(function({ variable, value }) {
      template = template.replace(variable, value);
    });

    return template;
  }

  /**
   * Handles the primary fetch request.
   *
   * @private
   * @method _fetchRequest
   * @async
   */
  _fetchRequest() {
    // Initiate the loading event.
    $(this).trigger('isLoading', [ true ]);

    const options = this.fetchOptions,
          uri     = this._buildEndpointURL();

    return new Promise((resolve, reject) => {
      fetch(uri, options)
        .then(res => res.json())
        .then(resolve)
        .catch(reject);
    })
    .then(this._handleResponse.bind(this))
    .catch(this._handleError.bind(this));
  }

  /**
   * Builds the fetch endpoint and appends the endpoint to the formated query params.
   *
   * @private
   * @method _buildURL
   * @return { String }
   */
  _buildURL() {
    const { searchURL, _buildQueryParams } = this;
    return `${searchURL}?${_buildQueryParams}`;
  }

  /**
   * Constructs the URL for the API backend.
   *
   * @private
   * @method _buildEndpointURL
   * @return { String }
   */
  _buildEndpointURL() {
    const { endpoint, _buildQueryParams } = this;
    return `${endpoint}?${_buildQueryParams}`;
  }

  /**
   * Allows for URL's to be created for pagination without triggering an Ajax
   * request.
   *
   * @private
   * @method _buildQueryParamsFaker
   * @param { String } param
   * @param { String|Boolean } value
   * @return { String }
   */
  _buildQueryParamsFaker(param, value) {
    const current = param + '=' + this.query[param],
          faked   = param + '=' + value;
    return this._buildURL().replace(current, faked);
  }

  /**
   * Handles Fetch error response.
   *
   * @private
   * @method _handleError
   * @param { JSON } Ajax error
   * @return undefined
   */
  _handleError(error) {
    $(this).trigger('isLoading', [ false ])
      .trigger('HandleError', [ error ]);
  }

  /**
   * Handles Fetch response and simple passes it to the client.
   *
   * @private
   * @method _handleResponse
   * @param { JSON } Ajax response
   * @return undefined
   */
  _handleResponse(response) {
    this.meta = response.meta;

    $(this).trigger('isLoading', [ false ])
      .trigger('HandleResponse', [ response ]);
  }

  /**
   * Helper function to quickly set or update search keywords.
   *
   * @private
   * @method _helperUpdateKeywords
   * @param { String } keywords
   * @return undefined
   */
  _helperUpdateKeywords(keywords) {
    const { keywordParam } = this;
    this.query._page = 1;
    this.query[keywordParam] = keywords;
    this._updatePushState();
  }

  /**
   * Gets variables from url first then reverts to default values.
   *
   * @private
   * @method _initQuery
   * @return { Object }
   */
  _initQuery() {
    const { defaultQueryParams, queryParams } = this;

    let defaults = defaultQueryParams;
    queryParams.map(function(query) {
      const context = query + '=([^&]*)',
            regex   = new RegExp(context),
            values  = window.location.search.match(regex);

      defaults[query] = !values ? defaults[query] : values[1];
    });

    return defaults;
  }

  _isEmptyQuery(param, value) {
    const { keywordParam } = this;
    return !value && param !== keywordParam;
  }

  /**
   * Updates the history API and triggers the fetch call to update the content.
   *
   * @private
   * @method _updatePushState
   * @return undefined
   */
  _updatePushState() {
    const url = this._buildURL();
    history.pushState({ search: url }, this.title, url);
    this._fetchRequest();
  }

  /**
   * Used to allow the frontend to update individual query parameters.
   *
   * @private
   * @method _updateQueryParam
   * @param { String } param
   * @param { String|Boolean } value
   * @return undefined
   */
  _updateQueryParam(param, value) {
    this.query[param] = value;
    this._updatePushState();
  }
};

module.exports = Search;
