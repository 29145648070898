/* eslint no-unused-vars: ["error", { "varsIgnorePattern": "datepicker" }] */
// https://github.com/fengyuanchen/datepicker

import datepicker from '@chenfengyuan/datepicker';

export default () => {

  $('[data-toggle="datepicker"]').datepicker({
    autoHide: true,
    zIndex: 2048
  });

  $('[data-toggle="datepickerfuture"]').datepicker({
    autoHide: true,
    startDate: new Date(),
    zIndex: 2048
  });

};
