export default () => {

  if ($('.ad-script').length) {

    const adContainers = document.getElementsByClassName('ad-script');

    for (const adContainer of adContainers) {
      const adId = adContainer.id;
      const scriptTag = document.createElement('script');
      scriptTag.type = 'text/javascript';
      scriptTag.text = "googletag.cmd.push(function() {googletag.display('" + adId + "');});";
      adContainer.innerHTML = '';
      adContainer.appendChild(scriptTag);
    }

  }

};
