import throttle from '../../../js-plugins/throttle';

export default () => {

  if ($('.sticky-search-header-search').length) {

    $('.sticky-search-header-search').on('focus', '.magic-box', function() {
      $(this).parents('.sticky-search-header-search').addClass('-active');
    });

    $('.sticky-search-header-search').on('blur', '.magic-box', function() {
      $(this).parents('.sticky-search-header-search').removeClass('-active');
    });

    $('.sticky-search-header-search-close').on('click', (e) => {
      e.preventDefault();
    });
  }

  const stickySearchHeader = $('.sticky-search-header');

  function checkScroll() {

    const scroll = $(window).scrollTop();

    if (scroll >= 220) {
      stickySearchHeader.removeClass('-unstuck').addClass('-stuck');
    } else {
      stickySearchHeader.removeClass('-stuck').addClass('-unstuck');
    }
  }

  // Sticky Header Stickyness
  if ($('.sticky-search-header').length) {

    // on load
    checkScroll();

    // on scroll
    $(window).on('scroll', throttle(function() {
      checkScroll();
    }, 50));

  }
};
