/* eslint no-unused-vars: ["error", { "varsIgnorePattern": "Mark" }] */
import Mark from 'mark.js';
import getOffset from '../../../js-plugins/get-offset';

export default () => {

  // checks if word is within viewport
  $.fn.isInViewport = function() {
    const elementTop = $(this).offset().top;
    const elementBottom = elementTop + $(this).outerHeight();
    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  if ($('.search-in-page-form')) {
    $('.search-in-page-form').submit(false);
    $('.search-page-trigger').on('click', (e) => {
      e.preventDefault();
    });
  }

  const context = document.querySelector('.-js-searchable');
  const $context = $('.-js-searchable');
  const markIt = new Mark(context);

  let keyword;
  const mark = function(e) {

    // Read the keyword
    if (e === 'mobile') {
      keyword = $('.keyword-search-input-mobile').val();
    } else {
      keyword = $('.keyword-search-input').val();
    }

    // Remove previous marked elements and mark
    // the new keyword inside the context
    markIt.unmark({
      done: () => {

        markIt.mark(keyword, {
          separateWordSearch: false
        });

        // scroll to first mark if it is not within the viewport
        const $results = $context.find('mark:first-of-type');

        if ($results.length) {

          if (!$($results).isInViewport()) {

            const headerOffset = getOffset();
            const position = $results.offset().top - headerOffset;

            $('html, body').stop().animate({
              'scrollTop': position
            }, 500);
          }
        }
      }
    });
  };

  $('.keyword-search-input').on('input', mark);
  $('.keyword-search-input-mobile').on('input', function() {
    const e = 'mobile';
    mark(e);
  });

};
