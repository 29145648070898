export default () => {

  if ($('.accordion').length) {

    $('.accordion-trigger:not(.-no-content)').on('click', (e) => {
      e.preventDefault();

      const $this = $(e.currentTarget),
            $item = $this.parent(),
            $content = $this.next();

      $content.slideToggle(300, () => {
        $(e.currentTarget).parent().toggleClass('-active');
      });

      $this.attr('aria-expanded', $item.hasClass('-active') ? 'false' : 'true');
      $content.attr('aria-hidden', $item.hasClass('-active') ? 'true' : 'false');

    });


    // Expand/Collapse All Accordion Items
    $('.accordion-expand-trigger').on('click', (e) => {
      e.preventDefault();

      const $this = $(e.currentTarget);
      const $items = $this.parents('.accordion-container').find('.accordion-content');
      const expanded = $this.attr('data-expanded') === 'true' ? 'false' : 'true';

      expanded === 'true' ? $this.text('Collapse all') : $this.text('Expand all');

      $items.map((i, el) => {
        if (expanded === 'true') {
          $(el).slideDown(300);
          $(el).parent().addClass('-active');
        } else {
          $(el).slideUp(300);
          $(el).parent().removeClass('-active');
        }
      });

      $this.attr('data-expanded', expanded);

    });

  }

};
