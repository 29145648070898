/* eslint-disable no-unused-vars */
import slick from 'slick-carousel';
import magnificPopup from 'magnific-popup';

export default () => {
  if ($('.product-carousel').length) {

    $('.product-carousel-slides').slick({
      autoplay: false,
      autoplaySpeed: 4000,
      arrows: true,
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      pauseOnHover: false,
      draggable: true,
      cssEase: 'ease'
    });

    if ($('.product-slide-image').length) {
      $('.product-slide-image').magnificPopup({
        type: 'image',
        callbacks: {
          open: () => {
            $(document).find($('div.mfp-content')).addClass('mfp-product-image-wrapper');
          },
          close: () => {
            $(document).find($('div.mfp-content')).removeClass('mfp-product-image-wrapper');
          }
        }
      });
    }
  }
};
