import throttle from '../../../js-plugins/throttle';
import imagesLoaded from 'imagesloaded';
import getOffset from '../../../js-plugins/get-offset';

export default () => {

  const subnavStickyEl = $('.-js-subnav-sticky');
  const subnavStickyHeight = subnavStickyEl.outerHeight();
  if (subnavStickyEl.length) {

    const stopPoint = getTop();
    const subnavSticky = subnavStickyEl.isSticky({
      zIndex: 99,
      stopper: stopPoint,
      offset: 95
    });
    subnavSticky.init();

    const stickyPoint = subnavStickyEl.offset().top - subnavSticky.offset;

    imagesLoaded.makeJQueryPlugin($);
    $('body').imagesLoaded(() => {
      subnavSticky.setTop(getTop());
      toggleSubnavSticky(subnavSticky);
    });

    const threshold = 900;
    let initialDiff = ($(window).outerWidth() > threshold) ? 1 : -1;

    $(window).on('resize', throttle(() => {

      subnavSticky.setTop(getTop());

      if ($(window).outerWidth() >= 900 && $(window).outerWidth() <= 999) {
        subnavSticky.updateOffset(20);
      } else {
        subnavSticky.updateOffset(95);
      }

      const w = $(window).outerWidth();
      const currentDiff = w - threshold;
      if (currentDiff * initialDiff < 0) {
        initialDiff *= -1;
        toggleSubnavSticky(subnavSticky);
        subnavSticky.resizeUpdateStickyPoint(stickyPoint);
      }
    }, 500));
  }

  function toggleSubnavSticky(e) {

    if ($(window).outerWidth() >= 900 && $(window).outerWidth() <= 999) {
      e.updateOffset(20);
    }

    if ($(window).outerWidth() >= 900) {
      e.init();
    } else {
      e.destroy();
    }
  }

  function getTop() {
    return ($('.site-footer').offset().top - subnavStickyHeight) - 90;
  }


  // scrollto anchorpoint for topics/myacog/clinical sticky sidenav component
  // $('.subnav-sticky-menu > li > a, .jump-links ul > li > a, .clinical-mobile-jump-links > li > a').on('click', (e) => {
  $('html').on('click', '.subnav-sticky-menu > li > a, .jump-links ul > li > a, .clinical-mobile-jump-links > li > a, .internal-jump-link', (e) => {
    e.preventDefault();

    const anchorID = e.currentTarget.hash;
    const $target = $(anchorID);
    const headerOffset = getOffset();

    if ($target.length) {

      const pos = $target.offset().top - headerOffset;

      $('html, body').stop().animate({
        'scrollTop': pos
      }, 500);
    }

  });

  // dyanamically create sidenav links based on panels in content area (used on topics pages only)
  if ($('#subnav-sticky-dynamic').length) {

    const menu = document.getElementById('subnav-sticky-dynamic');
    const mobileMenu = document.getElementById('mobile-jump-links-dynamic');

    if (menu !== null || mobileMenu !== null) {

      const panelIds = ['clinical-information-panel', 'latest-news-panel', 'patient-resources-panel', 'practice-management-panel', 'education-events-panel', 'advocacy-panel', 'external-resources-panel'];
      const menuNames = ['Clinical Information', 'Latest News', 'Patient Resources', 'Practice Management', 'Education & Events', 'Advocacy', 'External Resources'];

      let i;
      for (i = 0; i <= panelIds.length; i++) {

        const panelId = panelIds[i];
        const panelDiv = document.getElementById(panelId);

        if (panelDiv === null)
          continue;

        if (mobileMenu !== null) {

          const aTag = document.createElement('a');
          aTag.setAttribute('href', '#' + panelId);
          aTag.innerText = menuNames[i];

          const liTag = document.createElement('li');
          liTag.appendChild(aTag);
          mobileMenu.appendChild(liTag);
        }

        if (menu !== null) {

          const aTag = document.createElement('a');
          aTag.setAttribute('href', '#' + panelId);
          aTag.innerText = menuNames[i];

          const liTag = document.createElement('li');
          liTag.appendChild(aTag);
          menu.appendChild(liTag);
        }
      }
    }
  }

};
