/* global process */

/* eslint-disable no-multi-spaces */
/* eslint-disable object-curly-spacing */
/* eslint-disable object-property-newline */
/* eslint-disable no-unused-vars */

import Config from '../../../js-plugins/is-search/Config';
import Pagination from '../../../js-plugins/is-search/Pagination';

export default class NewsLandingWrapper extends Pagination {
  /**
   * Initialize the Search.
   */
  constructor() {
    const urlParams = new URLSearchParams(window.location.search);
    const cat = urlParams.get('cat');

    const listingPage = document.getElementById('newslisting');
    const itemid = listingPage.dataset.itemId;

    // All available default query parameters.
    const config = new Config();

    config.endpoint = process.env.NEWS_ENDPOINT;
    config.searchURL = window.location.pathname;
    config.defaultQueryParams = { _limit: 10, _page: 1, _itemid: itemid, cat };
    config.pagerParam = '_page';

    super(config);

    // Event callback for Fetch requests which build the reults and pager.
    $(this)
      .on('isLoading', this._mixinIsLoading.bind(this))
      .on('HandleResponse', this._searchResponse.bind(this))
      .on('HandleResponse', this._mixinBuildPagination.bind(this))
    ;

    // Pagination
    this._searchContainer = '.news-landing-wrapper-items';
    this.mixinPagination();
  }

  get searchContainer() {
    return this._searchContainer || null;
  }

  set searchContainer(container) {
    this.searchContainer = container;
  }

  mixinPagination() {
    // UI based click events for pagination.
    $('html')
      .on('click', '.-js-is-search-pagination a', function(e) {
        e.preventDefault();
        $('html, body').animate({
          scrollTop: $('.news-landing-wrapper').offset().top
        }, 300);
      })
      .on('click', '.-js-is-search-pagination-prev', this.previousPage.bind(this))
      .on('click', '.-js-is-search-pagination-next', this.nextPage.bind(this))
      .on('click', '.-js-is-search-pagination-pages a', this._mixinPaginationTrigger.bind(this))
    ;
  }

  /**
   * Callback for build the pagintation based off the returned results.
   *
   * @private
   * @method _mixinBuildPagination
   */
  _mixinBuildPagination() {
    $('.-js-is-search-pagination-pages').empty();
    const pageTotal = this.totalPages;

    // Hide pagination arrows by default.
    // We trigger this once in the constructor() for the inital page load,
    // and this one if for sequential action.
    $('.-js-is-search-pagination-prev, .-js-is-search-pagination-next').hide();

    // Do not load the pager if we get 1 or less pages to display.
    if (pageTotal <= 1) {
      return;
    }

    $('.-js-is-search-pagination-mobile-total').text(pageTotal);

    $('.-js-is-search-pagination').show();

    $('.-js-is-search-pagination-prev, .-js-is-search-pagination-next').show().attr('data-disabled', 'false');

    if (this.currentPage === 1) {
      $('.-js-is-search-pagination-prev').attr('data-disabled', 'true');
    }

    if (this.currentPage === pageTotal) {
      $('.-js-is-search-pagination-next').attr('data-disabled', 'true');
    }

    this.buildPager().map(this._mixinBuildPaginationItem);
  }

  /**
   * Callback for building an individual pager item.
   *
   * @private
   * @method _mixinBuildPaginationItem
   * @param { Object } page The page object context for the current search results.
   */
  _mixinBuildPaginationItem(page) {
    const { current, link, text, value } = page,
          currentHTML = `<a href="#" data-page="${value}" data-current="true"><span class="sr-text">Page </span>${text}</li>`;

    let defaultHTML = `<a href="${link}" data-page="${value}"><span class="sr-text">Page </span>${text}</a>`;

    if (current === true) {
      $('.-js-is-search-pagination-mobile-current').text(page.value);
    }

    if (text === '...') {
      defaultHTML = `<span class="-js-is-search-pagination-pages-dots">${text}</span>`;
    }

    $('.-js-is-search-pagination-pages').append(current ? currentHTML : defaultHTML);
  }

  /**
   * Callback that allows for a progress loaded to be initialized and deinitialized.
   *
   * @private
   * @method _mixinIsLoading
   * @param { Object } event The jQuery event passed from on().
   * @param { Boolean } state true|false
   */
  _mixinIsLoading(event, state) {

    /* eslint-disable indent */
    switch (state) {
      case true:
        // Show progress html.
        $('.news-landing-wrapper-loader').show();
        $('.news-landing-wrapper-items').hide();
        break;

      default:
        // Hide progress html.
        $('.news-landing-wrapper-loader').hide();
        $('.news-landing-wrapper-items').show();
        break;
    }
    /* eslint-enable indent */
  }


  /**
   * Callback being used to as the click evnet for the pagintation.
   *
   * @private
   * @method _mixinPaginationTrigger
   * @param { Object } event The jQuery event passed from on().
   */
  _mixinPaginationTrigger(event) {
    event.preventDefault();

    const page = $(event.target).data('page');

    this._helperUpdatePage(page);
  }


  /**
   * Callback for the primary Fetch responses.
   *
   * @private
   * @method _searchResponse
   * @param { Object } event The jQuery event passed from on().
   * @param { JSON } response The raw response object.
   */
  _searchResponse(event, response) {

    const { results } = response,
          // { firstResult, lastResult, totalResults } = this,
          container = $('.news-landing-wrapper-items');

    // Empty out the current search results and stats.
    container.empty();
    $('.news-landing-wrapper-no-results').hide();

    // Stop if there are no results to show.
    if (!results.length) {
      $('.news-landing-wrapper-no-results').show();

      return;
    }

    // Build and output each individual search result.
    results.map(({ url, title, text, image, date, category }) => {
      // const excerpt = text.trunc(168);

      const template = `
      <a href="${url}" class="teaser">
        <div class="teaser-data">
          <div class="teaser-data-type">${category}</div>
          <div class="teaser-data-date">${date}</div>
        </div>
        <div class="teaser-content">
          <h3>${title}</h3>
          <p>${text}</p>
        </div>
        ${image ? `<div class="teaser-img"><img src="${image}" alt="Teaser"></div >` : ''}
      </a>
      `;

      container.append(template);
    });

  }
}
