/**
 * Extending jQuery
 */
export default () => {

  /**
   * Create :focusable selector for jQuery
   *
   * @examples
   * $(:focusable).on('focus', () => { ... });
   * $(.element > :focusable).on('focus', () => { ... });
   * $variable.find(':focusable').on('focus', () => { ... });
   *
   */
  $.extend($.expr[':'], {
    focusable(el) {
      return $(el).is('a, button, :input, [tabindex]');
    }
  });

};
