/* eslint-disable no-unused-vars */
import slick from 'slick-carousel';

export default () => {

  if ($('.layered-half-slider-container').length) {

    const originalSlider = $('.layered-half-slider-container-slick.-original');
    const bottomSlider = $('.layered-half-slider-container-slick').clone().removeClass('-original').addClass('-bottom-slider')
      .prependTo('.layered-half-slider-container');

    $('.-bottom-slider .layered-half-slider-container-item').eq(0).appendTo('.-bottom-slider');

    originalSlider.slick({
      speed: 500,
      dots: true,
      arrows: true,
      accessibility: true,
      slidesToShow: 1,
      asNavFor: '.layered-half-slider-container-slick.-bottom-slider',
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: 0,
      responsive: [
        {
          breakpoint: 700,
          settings: {
            dots: false
          }
        }
      ]
    });

    $('.layered-half-slider-container-slick.-bottom-slider').slick({
      speed: 500,
      dots: false,
      arrows: false,
      accessibility: false,
      slidesToShow: 1,
      slidesToScroll: 1
    });

    // add aria live label to announce new content
    // $('.slick-arrow').on('click', () => {
    //   $('.slick-slide').removeAttr('aria-live');
    //   $('.slick-active').attr('aria-live', 'new slide content');
    // });

    // changing aria labels for AA
    originalSlider.find('.slick-prev').attr('aria-label', 'Previous Article');
    originalSlider.find('.slick-next').attr('aria-label', 'Next Article');

    const originalSliderDots = originalSlider.find('.slick-dots li');
    originalSliderDots.each((index, element) => {
      const button = $(element).find('button');
      const label = button.attr('aria-label');
      button.attr('aria-label', `Article ${label}`);
    });

  }

};
