/*
 * isSticky
 * Plugin
*/
export default () => {

  $.fn.isSticky = function(options) {
    const defaults = {
      element: $(this),
      // Z-index of element
      zIndex: '',
      // Stopper element selector, also accepts number value
      stopper: '',
      // Offset from top of window
      offset: 0,
      // Class applied to element when it's stuck
      stuckClass: '-stuck'
    };

    // Accepts custom stopper id or class
    const settings = $.extend({}, defaults, options);

    // Where we want it to start being sticky
    let stickyPoint = settings.element.offset().top - settings.offset;

    // Elements width
    let thisWidth = settings.element.parent().outerWidth();

    // Cache a clone sticky element
    const placeholder = $('<div class="js-sticky-placeholder"></div>').width(thisWidth).height(settings.element.outerHeight());

    // Checks if stopper was defined
    settings.hasStopper = function() {
      return 0 < $(this.stopper).length || typeof this.stopper === 'number' ? true : false;
    };

    // Inserts a placeholder where the element used to be to prevent content from moving
    settings.insertPlaceholder = function() {
      return stickyPoint < $(window).scrollTop() && placeholder.length < 2 ? this.element.after(placeholder) : placeholder.remove();
    };

    // Sets position fixed vs static
    settings.setPosition = function() {
      return stickyPoint < $(window).scrollTop() ? 'fixed' : '';
    };

    settings.updateOffset = function(newOffset) {
      return this.offset = newOffset;
    };

    settings.updateStickyPoint = function() {
      return stickyPoint = settings.element.offset().top - settings.offset;
    };

    settings.resizeUpdateStickyPoint = function(sp) {
      return stickyPoint = sp;
    };

    // Sets top position
    settings.setTop = function(newTop) {
      let stopPoint = 0;
      if (newTop) {
        this.stopper = newTop;
      }

      if (typeof this.stopper === 'number') {
        stopPoint = this.stopper - this.offset;
      } else if (0 < $(this.stopper).length) {
        stopPoint = $(this.stopper).offset().top - this.element.outerHeight();
      }

      const data = {
        windowTop: $(window).scrollTop(),
        stopPoint
      };


      if (stickyPoint < data.windowTop) {
        if (settings.hasStopper && data.stopPoint < data.windowTop) {
          return data.stopPoint - data.windowTop + this.offset;
        }
        return 0 + this.offset;
      }
      return '';
    };

    // Sets element's width when stuck
    settings.setWidth = function(e) {

      if (e.type === 'resize') {

        // Re-calculate width
        // const thisWidth = settings.element.parent().outerWidth();
        const thisWidth = settings.element.outerWidth();

        // Reset width/height of placeholder
        placeholder.width(thisWidth).height(settings.element.outerHeight());
      }

      return stickyPoint < $(window).scrollTop() ? thisWidth : '';
    };

    settings.updateWidth = function() {
      return thisWidth = settings.element.parent().outerWidth();
    };

    // Sets element's z-index when stuck
    settings.setIndex = function() {
      return typeof this.zIndex === 'number' && stickyPoint < $(window).scrollTop() ? this.zIndex : '';
    };

    // Sets element's class when stuck
    settings.setClass = function() {
      return stickyPoint < $(window).scrollTop() ? this.element.addClass(this.stuckClass) : this.element.removeClass(this.stuckClass);
    };

    // Appplies position, top, width and z-index when stuck
    settings.applyCSS = function(e) {
      return this.element.css({
        'position': settings.setPosition(),
        'top': settings.setTop(),
        'width': settings.setWidth(e),
        'z-index': settings.setIndex()
      });
    };

    // Resets CSS of element
    settings.resetCSS = function() {
      return this.element.css({
        'position': '',
        'top': '',
        'width': '',
        'z-index': ''
      });
    };

    // Removes placeholder element
    settings.removePlaceholder = function() {
      return placeholder.remove();
    };

    // Wrapper function to call necessary functions for functionality
    settings.stickyScroll = function(e) {
      settings.applyCSS(e);
      settings.setClass();
      settings.insertPlaceholder();
    };

    // Initializes the sticky function and binds
    settings.init = function() {

      settings.updateStickyPoint();
      settings.updateWidth();

      if ($(window).innerHeight() > this.element.outerHeight()) {
        $(window).on('scroll load resize', this.stickyScroll);
      }
    };

    // Removes event binding for sticky function, removes placeholder, and resets CSS of element
    settings.destroy = function() {
      $(window).off('scroll load resize', this.stickyScroll);
      settings.resetCSS();
      settings.removePlaceholder();
    };

    return settings;
  };

  // OLD
  // $.fn.isSticky = function(options) {
  //   const defaults = {
  //     element: $(this),
  //     // Z-index of element
  //     zIndex: '',
  //     // Stopper element selector, also accepts number value
  //     stopper: '',
  //     stopPoint: 0,
  //     // Class applied to element when it's stuck
  //     stuckClass: '-stuck',
  //     // offset from the top in case you have something like a fixed header
  //     offset: 0,
  //     table: false
  //   };
  //   // Accepts custom stopper id or class
  //   const settings = $.extend({}, defaults, options);
  //
  //   // Elements width
  //   let thisWidth = settings.element.parent().outerWidth();
  //
  //   // Cache a clone sticky element
  //   const placeholder = $(this).clone()
  //     .width(thisWidth)
  //     .height(settings.element.outerHeight())
  //     .addClass('-cloned')
  //     .hide()
  //   ;
  //
  //   // Where we want it to start being sticky
  //   const stickyPoint = settings.element.offset().top - settings.offset;
  //
  //   // Checks if stopper was defined
  //   settings.hasStopper = function() {
  //     return 0 < $(this.stopper).length || typeof this.stopper === 'number' ? true : false;
  //   };
  //
  //   // Inserts a placeholder where the element used to be to prevent content from moving
  //   settings.insertPlaceholder = function() {
  //     return this.element.after(placeholder);
  //   };
  //   settings.insertPlaceholder();
  //
  //   // Sets position fixed vs static
  //   settings.setPosition = function() {
  //     return stickyPoint < $(window).scrollTop() ? 'fixed' : '';
  //   };
  //
  //   // Sets top position
  //   settings.setStop = function(newTop) {
  //     if (newTop) {
  //       this.stopper = newTop;
  //     }
  //
  //     if (typeof this.stopper === 'number') {
  //       this.stopPoint = this.stopper;
  //     } else if (0 < $(this.stopper).length) {
  //       this.stopPoint = $(this.stopper).offset().top - this.element.outerHeight() - this.offset;
  //
  //     }
  //   };
  //
  //   // Sets element's width when stuck
  //   settings.setWidth = function(e) {
  //
  //     if (e.type === 'resize') {
  //
  //       // Re-calculate width
  //       thisWidth = settings.element.parent().outerWidth();
  //
  //       // Reset width/height of placeholder
  //       placeholder.width(thisWidth).height(settings.element.outerHeight());
  //     }
  //
  //     return stickyPoint < $(window).scrollTop() ? thisWidth : '';
  //   };
  //
  //   // Sets element's z-index when stuck
  //   settings.setIndex = function() {
  //     return typeof this.zIndex === 'number' && stickyPoint < $(window).scrollTop() ? this.zIndex : '';
  //   };
  //
  //   // Sets element's class when stuck
  //   settings.setClass = function() {
  //     return stickyPoint < $(window).scrollTop() ? this.element.addClass(this.stuckClass) : this.element.removeClass(this.stuckClass);
  //   };
  //
  //   // Appplies position, top, width and z-index when stuck
  //   settings.applyCSS = function(e) {
  //     return $(placeholder).css({
  //       'position': 'fixed',
  //       'top': this.offset + 'px',
  //       'width': settings.setWidth(e),
  //       'z-index': settings.setIndex()
  //     });
  //   };
  //
  //   // Removes placeholder element
  //   settings.removePlaceholder = function() {
  //     return placeholder.remove();
  //   };
  //
  //   // If this is for a table header, then we need to make
  //   // sure we are also keeping the inner cells widths updated
  //   settings.updateTableHeaders = function() {
  //     if (this.table) {
  //       const clonedThs = this.element.find('th');
  //       $(placeholder).find('th').map((i, th) => {
  //         $(th).width(clonedThs.eq(i).width());
  //       });
  //     }
  //   };
  //
  //   // Actually does the turning on and off of the stickiness
  //   settings.toggleSticky = function() {
  //     const windowTop = $(window).scrollTop();
  //
  //     if (stickyPoint < windowTop) {
  //       $(placeholder).show();
  //       if (settings.hasStopper() && this.stopPoint < windowTop) {
  //         $(placeholder).hide();
  //       }
  //     } else {
  //       $(placeholder).hide();
  //     }
  //   };
  //
  //   // Wrapper function to call necessary functions for functionality
  //   settings.stickyScroll = function(e) {
  //     settings.applyCSS(e);
  //     settings.updateTableHeaders();
  //     settings.setClass();
  //     settings.setStop();
  //     settings.toggleSticky();
  //   };
  //
  //   // Initializes the sticky function and binds
  //   settings.init = function() {
  //     if ($(window).innerHeight() > this.element.outerHeight()) {
  //       $(window).on('scroll load resize', this.stickyScroll);
  //     }
  //   };
  //
  //   // Removes event binding for sticky function, removes placeholder, and resets CSS of element
  //   settings.destroy = function() {
  //     $(window).off('scroll load resize', this.stickyScroll);
  //     settings.resetCSS();
  //     settings.removePlaceholder();
  //   };
  //
  //   return settings;
  // };

};
