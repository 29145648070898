import throttle from '../../js-plugins/throttle';
import imagesLoaded from 'imagesloaded';

export default () => {

  const stickyElement = $('.right-rail-promo.-sticky');

  if ($('.right-rail-promo.-sticky').length) {

    let topOffset = getOffset();
    const stopPoint = getTop();

    const rightRailSticky = stickyElement.isSticky({
      zIndex: 99,
      stopper: stopPoint,
      offset: 0
    });

    imagesLoaded.makeJQueryPlugin($);
    $('body').imagesLoaded(() => {

      if ($(window).width() >= 900) {
        rightRailSticky.init();
        rightRailSticky.updateOffset(topOffset);
        rightRailSticky.updateStickyPoint();
      } else {
        rightRailSticky.destroy();
      }

    });

    $(window).on('resize', throttle(() => {

      rightRailSticky.setTop(getTop());
      topOffset = getOffset();

      if ($(window).width() >= 900) {
        rightRailSticky.init();
        rightRailSticky.updateOffset(topOffset);
      } else {
        rightRailSticky.destroy();
      }

      rightRailSticky.updateStickyPoint();

    }, 1000));

  }

  function getOffset() {

    let topOffset = 0;

    if ($(window).width() >= 900 && $(window).width() < 999) {
      topOffset = 20;
    } else if ($(window).width() >= 1000) {
      topOffset = $('.sticky-search-header').outerHeight() + 20;
    } else {
      topOffset = 0;
    }

    return topOffset;

  }

  function getTop() {
    return stickyElement.parent().offset().top + stickyElement.parent().outerHeight() - stickyElement.outerHeight();
  }

};
