import priorityNav from 'priority-nav';

export default () => {

  if ($('.section-subnav').length) {
    const activeNav = $('.section-subnav li.-active a').text();

    priorityNav.init({
      initClass: 'js-priorityNav',
      mainNavWrapper: '.section-subnav',
      navDropdownClassName: 'priority-nav-dropdown',
      navDropdownToggleClassName: 'nav-dropdown-toggle',
      navDropdownLabel: 'More...',
      navDropdownBreakpointLabel: activeNav,
      breakPoint: 600,
      throttleDelay: '50'
    });

    // removing vertical lines (redo psuedo's so won't need this??)
    $('.section-subnav li').hover(
      function() {
        if ($(this).is(':last-child')) {
          $('.nav-dropdown-toggle').addClass('-kill-line');
        }
        $(this).prev().addClass('-kill-line');
      }, function() {
        if ($(this).is(':last-child')) {
          $('.nav-dropdown-toggle').removeClass('-kill-line');
        }
        $(this).prev().removeClass('-kill-line');
      }
    );

    $('.nav-dropdown-toggle').hover(
      function() {
        $(this).addClass('-kill-line');
      }, function() {
        $(this).removeClass('-kill-line');
      }
    );

    // adding for AA
    $('.priority-nav__dropdown-toggle').on('click', (e) => {
      const expanded = $(e.currentTarget).attr('aria-expanded') === 'true' ? 'false' : 'true';
      $(e.currentTarget).attr('aria-expanded', expanded);
    });
  }

};
