import focusWithin from '@plugins/focus-within';
// import throttle from '../../js-plugins/throttle';

export default () => {

  // Toggle class on nav items on focus/blur of descendants
  focusWithin('.site-header-main-nav > li.-has-dropdown', '-dropdown-open');
  focusWithin('.my-acog-links-dropdown-container', '-dropdown-open');


  // opens/closes coveo search (Clinical Section only)
  $('.clinical-search-trigger').on('click', (e) => {

    e.preventDefault();
    $(e.target).toggleClass('-active');
    $('.site-header-clinical-search').toggleClass('-active');

    setTimeout(function() {

      if ($(e.target).hasClass('-active')) {
        $('.site-header-clinical-search .magic-box-input input').focus();
      }
    }, 250);

  });

  // these were all supposed to have landing pages so here's a quick "fix"
  $('.site-header-main-nav > li > a[aria-role="button"]').on('click', (e) => {
    e.preventDefault();
  });

  $('.site-header .site-header-main-nav > li.-has-dropdown')
    .on('mouseenter', (e) => {
      const $item = $(e.currentTarget).find('>:first-child');
      $item.attr('aria-expanded', 'true');
      $item.next().attr('aria-hidden', 'false');
    })

    .on('focus', ':focusable', (e) => {
      const $item = $(e.currentTarget);
      $item.attr('aria-expanded', 'true');
      $item.next().attr('aria-hidden', 'false');
    });

  $('.site-header .site-header-main-nav > li.-has-dropdown')

    .on('mouseleave', (e) => {
      const $item = $(e.currentTarget).find('>:first-child');
      $item.attr('aria-expanded', 'false');
      $item.next().attr('aria-hidden', 'true');
    })

    .on('focusout', (e) => {

      const $item = $(e.currentTarget).find('>:first-child');
      const $dropdown = $(e.currentTarget).find('.main-nav-dropdown');

      if (e.currentTarget.contains(e.relatedTarget)) {
        return;
      }

      $item.attr('aria-expanded', 'false');
      $dropdown.attr('aria-hidden', 'true');

    });

};
