import throttle from '../../../js-plugins/throttle';
import imagesLoaded from 'imagesloaded';
export default () => {

  const articleDetailMobileToolbarEl = $('.article-detail-mobile-toolbar');
  if (articleDetailMobileToolbarEl.length) {

    const stopPoint = getTop();
    const subnavSticky = articleDetailMobileToolbarEl.isSticky({
      zIndex: 99,
      stopper: stopPoint,
      offset: 0
    });

    imagesLoaded.makeJQueryPlugin($);
    $('body').imagesLoaded(() => {
      subnavSticky.setTop(getTop());

      if ($(window).width() < 1200) {
        subnavSticky.init();
      } else {
        subnavSticky.destroy();
      }
    });

    $(window).on('resize', throttle(() => {

      subnavSticky.setTop(getTop());

      if ($(window).width() < 1200) {
        subnavSticky.init();
      } else {
        subnavSticky.destroy();
      }
    }, 500));

  }

  function getTop() {
    return ($('.site-footer').offset().top - articleDetailMobileToolbarEl.outerHeight()) - 90;
  }

  if ($('.article-detail-mobile-toolbar').length) {

    $('.article-detail-mobile-toobar-link').on('click', (e) => {
      e.preventDefault();
      $('.article-detail-mobile-toolbar > ul > li').removeClass('-active');
      $('.article-detail-mobile-content').hide();
      $(e.target).parent().addClass('-active');
      $(e.target).next('.article-detail-mobile-content').show();
    });

    $('.article-detail-mobile-close, .clinical-mobile-jump-links > li > a').on('click', (e) => {
      e.preventDefault();
      $('.article-detail-mobile-toolbar > ul > li').removeClass('-active');
      $(e.target).parents('.article-detail-mobile-content').hide();
    });

    $('.main-content-container:not(.-is-disabled) .article-detail-mobile-toolbar .-search-page').on('click', () => {
      $('.keyword-search-input-mobile').focus();
    });

  }

};
