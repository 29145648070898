// https://github.com/dimsemenov/Magnific-Popup
/* eslint no-unused-vars: ["error", { "varsIgnorePattern": "magnificPopup" }] */

import magnificPopup from 'magnific-popup';

export default () => {

  // default size
  $('.modal-trigger').magnificPopup({
    type: 'inline',
    closeOnBgClick: true,
    tLoading: '',
    focus: 'input, a, button',
    closeMarkup: '<button class="mfp-close -modal-close">Close notification</button>'
  });

  // login modal
  $('.modal-trigger.-login-oauth').magnificPopup({
    type: 'inline',
    mainClass: '-mfp-login-oauth',
    closeOnBgClick: true,
    tLoading: '',
    focus: 'input, a, button',
    closeMarkup: '<button class="mfp-close -modal-close">Close notification</button>'
  });

  // small version (used for myacog delete item)
  $('.modal-trigger.-small').magnificPopup({
    type: 'inline',
    mainClass: '-mfp-small',
    closeOnBgClick: true,
    tLoading: '',
    focus: 'input, a, button',
    closeMarkup: '<button class="mfp-close -modal-close">Close notification</button>'
  });

  // practice bulletin figures modal
  $('.modal-trigger.-figures').magnificPopup({
    type: 'inline',
    mainClass: '-mfp-figures',
    closeOnBgClick: true,
    tLoading: '',
    focus: '.slick-next, .mfp-close',
    closeMarkup: '<button class="mfp-close -modal-close">Close notification</button>'
  });

  // for youtube/vimeo modals
  $('.modal-video').magnificPopup({
    disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    focus: 'input, a, button'
  });

  $('.mfp-cancel').click(function(e) {
    e.preventDefault();
    $('.mfp-close').trigger('click');
  });

};
