export default () => {

  if ($('.expander').length) {

    $('.expander-trigger').on('click', (e) => {

      const $trigger = $(e.target);
      const expandedState = $(e.target).attr('aria-expanded');

      e.preventDefault();
      $trigger.parent().toggleClass('-active');
      $trigger.attr('aria-expanded', (expandedState === 'true' ? 'false' : 'true'));

      $trigger.next()
        .attr('aria-hidden', (expandedState === 'true' ? 'true' : 'false'))
        .slideToggle(200);

    });
  }

};
