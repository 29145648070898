export default () => {

  const closeSpeed = 300;
  const openSpeed = 300;

  const swapVisible = (elHide, elShow) => {
    elHide.slideUp(closeSpeed);
    elShow.slideDown(openSpeed);
  };

  const scrollToEl = (elScroll) => {
    $('html, body').animate({
      scrollTop: (elScroll.offset().top)
    }, 350);
  };

  // MYACOG PROFILE EDIT/ADD/CANCEL
  if ($('.myacog-profile-block').length) {

    // edit section
    $('.-js-edit-profile').on('click', (e) => {
      e.preventDefault();
      $(e.target).hide();
      const elHide = $(e.target).parents('.myacog-profile-block').find('.-js-profile-completed');
      const elShow = $(e.target).parents('.myacog-profile-block').find('.-js-profile-edit');
      swapVisible(elHide, elShow);
    });

    // cancel edit section
    $('.-js-form-cancel').on('click', (e) => {
      e.preventDefault();
      const elScroll = $(e.target).parents('.myacog-profile-block');
      const elShow = $(e.target).parents('.myacog-profile-block').find('.-js-profile-completed');
      const elHide = $(e.target).parents('.myacog-profile-block').find('.-js-profile-edit');
      $(e.target).parents('.myacog-profile-block').find('.-js-edit-profile').show();
      swapVisible(elHide, elShow);

      // scroll to top of section
      scrollToEl(elScroll);
    });

    // add new item
    $('.-js-add-new').on('click', (e) => {
      e.preventDefault();
      $(e.target).hide();
      const elHide = $(e.target).parents('.myacog-profile-block-subsection').find('.-js-profile-completed');
      const elShow = $(e.target).parents('.myacog-profile-block-subsection').find('.-js-profile-add');
      swapVisible(elHide, elShow);
    });

    // cancel add new item
    $('.-js-form-add-cancel').on('click', (e) => {
      e.preventDefault();
      const elShow = $(e.target).parents('.myacog-profile-block').find('.-js-profile-completed');
      const elHide = $(e.target).parents('.myacog-profile-block').find('.-js-profile-add');
      $(e.target).parents('.myacog-profile-block').find('.-js-add-new').show();
      swapVisible(elHide, elShow);
    });

    // edit individual item
    $('.-js-edit-item').on('click', (e) => {
      e.preventDefault();
      const elHide = $(e.target).parents('.-js-profile-item-edit');
      const elShow = $(e.target).parents('.profile-block-item-editable').find('.-js-profile-edit');
      swapVisible(elHide, elShow);
    });

    // cancel edit individual item
    $('.-js-form-edit-item-cancel').on('click', (e) => {
      e.preventDefault();
      const elShow = $(e.target).parents('.profile-block-item-editable').find('.-js-profile-item-edit');
      const elHide = $(e.target).parents('.profile-block-item-editable').find('.-js-profile-edit');
      swapVisible(elHide, elShow);
    });

    // toggle 'Other' field when 'other' is selected
    $('.has-other-select').on('change', (e) => {
      $(e.target).val() === 'other' ? $(e.target).parents('.form-row').find('.-select-other').addClass('-active') : $('.-select-other').removeClass('-active');
    });

  }

  // MYACOG PROFILE CHANGE PASSWORD
  if ($('.profile-change-password').length) {

    // password requirements show
    $('#new-password').on('click', (e) => {
      e.preventDefault();
      if (!$('.profile-change-password-requirements').is(':visible')) {
        $('.profile-change-password-requirements').slideDown(200);
      }
    });

    // reset change password form
    $('.change-password-cancel').on('click', (e) => {
      e.preventDefault();
      $('.profile-change-password').trigger('reset');
    });

  }

};
