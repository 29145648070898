// -----------------------------------------------------------------------------
// Component Includes
// -----------------------------------------------------------------------------
import extendJquery from '@plugins/extend-jquery';
import isSticky from '@plugins/is-sticky';
import siteHeader from '@components/site-header';
import mobileNav from '@nav/mobile-nav';
import siteFooter from '@components/site-footer';
import stickySearchHeader from '@nav/sticky-search-header';
import subnav from '@nav/subnav';
import jumpMenu from '@promos/promo-dropdown';
import sidebarSearchFilters from '@sidebar/sidebar-search-filters';
import promoBarSticky from '@promos/promo-bar-sticky';
import subnavSticky from '@subnavSticky/subnav-sticky';
import subnavStickyClinical from '@subnavSticky/subnav-sticky-clinical';
import NewsLandingWrapper from '@news/news-landing-wrapper';
import formValidation from '@plugins/form-validation';
import datepickerAcog from '@components/datepicker-acog';
import socialShare from '@components/social-share';
import sectionSubnav from '@nav/section-subnav';
import wysiwygContent from '@components/wysiwyg-content';
import modals from '@components/modals';
import accordion from '@components/accordion';
import expander from '@components/expander';
import rightRailPromo from '@components/right-rail-promo';
import layeredHalfSlider from '@components/layered-half-slider';
import heroMyAcog from '@heros/hero-myacog';
import tooltips from '@components/tooltips';
import myAcogProfileBlock from '@myacog/myacog-profile-block';
import fileUpload from '@components/file-upload';
import productCarousel from '@components/product-carousel';
import practiceBulletinDetail from '@clinical/practice-bulletin-detail';
import articleDetailMobileToolbar from '@clinical/article-detail-mobile-toolbar';
import practiceBulletinFiguresSlider from '@clinical/practice-bulletin-figures-slider';
import searchPage from '@clinical/search-in-page';
import cmeDisclosureForm from '@components/cme-disclosure-form';
import bulkModal from './components/ecommerce/bulk-modal/bulk-modal.js';
import adContainers from '@components/ad-containers-js';

// ---------------------------------------------------------------------------------------
// Vendor includes for use outside of the bundle (attached to window.interactiveVendor)
// ---------------------------------------------------------------------------------------
import magnificPopup from 'magnific-popup';
import tippy from 'tippy.js';
import priorityNav from 'priority-nav';
import datepicker from '@chenfengyuan/datepicker';

if (window.useInteractiveScripts) {

  window.interactiveVendor = {
    magnificPopup,
    tippy,
    priorityNav,
    datepicker
  };

  window.interactiveScripts = {
    modals,
    heroMyAcog,
    tooltips,
    myAcogProfileBlock,
    fileUpload,
    sectionSubnav,
    datepickerAcog,
    subnav,
    formValidation
  };

}

(($) => {

  // Document Ready
  $(() => {

    // -----------------
    // High-priority JS
    // -----------------
    extendJquery();
    siteHeader();
    mobileNav();
    stickySearchHeader();

    // News Faceting
    if ($('.news-landing-wrapper').length) {
      new NewsLandingWrapper().init();
    }

    socialShare();
    sectionSubnav();
    isSticky();
    modals();
    accordion();
    expander();
    jumpMenu();
    rightRailPromo();
    subnavSticky();
    subnavStickyClinical();
    sidebarSearchFilters();
    layeredHalfSlider();
    heroMyAcog();

    // -----------------
    // Low-priority JS
    // -----------------
    promoBarSticky();
    subnav();
    wysiwygContent();
    siteFooter();
    formValidation();
    tooltips();
    datepickerAcog();
    myAcogProfileBlock();
    fileUpload();
    practiceBulletinDetail();
    articleDetailMobileToolbar();
    practiceBulletinFiguresSlider();
    searchPage();
    productCarousel();
    cmeDisclosureForm();
    bulkModal();
    adContainers();

  });

})($);
