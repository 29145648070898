// get offset of headers
export default () => {

  const $articleDetailMobileToolbar = $('.article-detail-mobile-toolbar');
  const $stickySearchHeader = $('.sticky-search-header');

  let stickyHeaderHeight = 0;
  let articleDetailMobileToolbarHeight = 0;
  let buffer = 15;

  if ($(window).outerWidth() < 1000) {
    articleDetailMobileToolbarHeight = $articleDetailMobileToolbar.length ? $articleDetailMobileToolbar.outerHeight() : 0;
  } else if ($(window).outerWidth() >= 900 && $(window).outerWidth() <= 1199) {
    articleDetailMobileToolbarHeight = $articleDetailMobileToolbar.length ? $articleDetailMobileToolbar.outerHeight() : 0;
    stickyHeaderHeight = $stickySearchHeader.length ? $stickySearchHeader.outerHeight() : 0;
    buffer = 30;
  } else {
    stickyHeaderHeight = $stickySearchHeader.length ? $stickySearchHeader.outerHeight() : 0;
    buffer = 30;
  }

  const headerOffset = stickyHeaderHeight + articleDetailMobileToolbarHeight + buffer;
  return headerOffset;

};
