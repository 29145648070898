export default () => {

  // will be handled by coveo
  if ($('.sidebar-search-filters').length) {
    $('.-js-sidebar-search-filters-mobile-trigger').on('click', (e) => {
      e.preventDefault();

      $(e.target)
        .next('.-js-sidebar-search-filters-content')
        .slideToggle()
        .parent('.sidebar-search-filters')
        .toggleClass('-active');

    });
  }

  if ($('.acog-CoveoSearch-Product').length) {

    let open = false;

    const $controller = $('.product-search-mobile-filter'),
          $filters = $('.CoveoFacet');

    $(window).resize(() => {
      if (window.innerWidth >= 900) {
        $filters.css('display', 'block');
        return;
      }

      if (window.innerWidth < 900 && open) {
        $filters.css('display', 'block');
        $('.coveo-dropdown-header-wrapper').css('display', 'none');
        return;
      }

      if (window.innerWidth < 900 && !open) {
        $filters.css('display', 'none');
        $('.coveo-dropdown-header-wrapper').css('display', 'none');
      }
    });

    $controller.click((e) => {
      e.preventDefault();

      if (open) {
        open = false;
        $controller.removeClass('-opened');
        $controller.addClass('-closed');
        $filters.slideToggle();
      } else {
        open = true;
        $controller.removeClass('-closed');
        $controller.addClass('-opened');
        $filters.slideToggle();
      }
    });
  }


};
