(function($){

  'use strict';

  $.fn.pushMenu = function() {

    // idk about this
    var _this = this;

    // Define option defaults
    var defaults = {
      level: 0,
      menu: '',
      position: 0,
      maxDepth: -1,
      ul: 'ul',
      li: 'li',
      hideMenu: true,
      data: 'data-menu',
      mobileNav: '',
      target: this[0],
      nextHTML: '',
      prevHTML: '',
      navButton: '',
      pageContainer: '',
      scrollBlocker: '',
      resetOnClose: true,
      contents: function(selectedContent) {
        return selectedContent;
      }
    };

    // Create options by extending defaults with the passed in arugments
    if (arguments[0] && typeof arguments[0] === "object") {
      this.options = this.extend(true, defaults, arguments[0]);
    }

    this.reg = function(opts) {
      $(this.options.target).data('opts', opts);
    };

    this.getOpts = function(opt) {
      return $(this.options.target).data('opts');
    };

    this.getContent = function() {
      return $(_this.options.menu).clone()[0];
    };

    /**
     * Function for setting up the menus
     */
     this.setup = function(index, item) {
       if (index === 0) $(item).attr(_this.options.data, 0);
       var cloned = item.cloneNode(true);

       return {
         unique: index,
         menu: _this.removeChildren(cloned)
       };
     };

     /**
      * Helper function to get menu levels
      */
     this.menuContainers = function(index) {
       return '<' + _this.options.ul + ' data-position="' + index +'" />';
     };

    /**
     * .. it works?
     */
    this.removeChildren = function(cloned) {
      $(cloned).find(this.options.li + ' > ' + this.options.ul).children().remove();
      return cloned;
    };

    /**
     * Change menu levels and resetsdfas
     */
    this.change = function(event, level) {

      // here are your options .. do or do not do .. there is no try
      var opts = _this.getOpts();

      // now some functions
      var appendContentArray = function(target, content) {
        for (var x=0;x < content.length; x++) {
          if ($(content[x]).children(_this.options.ul).length >= 1) {
            $(content[x])
              .append(_this.options.nextHTML)
              .find(_this.options.ul)
              .remove();
          }
        }
        target.append(content);
      };
      var updateDrawer = function(options) {
        $(_this.options.mobileNav).attr('data-current', _this.options.position);
      };

      // more variable .. haven't done anything yet
      var contents = opts.content,
        total = opts.position,
        items = $(contents[level].menu.children).clone(),
        length = items.length + 1,
        nextHTMLName = typeof event === 'object' ? event.target.dataset.direction : event;

      // let the games BEGIN!
      switch (nextHTMLName) {
        // reset menu to level 0
        case 'reset':
            for(;total>0;total--) {
              $(opts.target).find(opts.ul + '[data-position=' + opts.position + ']').empty();
              opts.position--;
              updateDrawer(opts);
            }

          break;
        // close submenus
        case 'prev':
          // Update Target.
            $(opts.target).children(this.options.ul + '[data-position=' + (this.options.position) + ']').empty();

          opts.position--;
          updateDrawer(opts);
          break;

        // everything else .. mostly open sub menus
        default:
          if (event && event.target.dataset.direction === 'next') {
            opts.position++;
          }

          var currentUL = $(opts.target).find(opts.ul + '[data-position="'+ opts.position + '"]');

          if (event) {
            currentUL.prepend(opts.prevHTML);
          }

          // content is in the form of li's in items array
          appendContentArray(currentUL, items);

          // Update Target.
          updateDrawer(opts);
          if (typeof contents[level].menu.dataset !== 'object') {
            contents[level].menu.dataset = {};
          }
          contents[level].menu.dataset.position = opts.position;
      }

      this.reg(opts);

    };

    /**
     * Updates data attribute on drawer container
     */
    this.addDataAttr = function(content) {
      var counter = 1;
      return content.map(function(index, item) {
        $(item).find('> ' + _this.options.ul).map(function(position, ul) {
          $(item).attr(_this.options.data, counter);
          counter++;
        });
      });
    };

    /**
     * Gets the max depth for stuff
     */
     this.maxDepth = function(content) {
       var options = this.options,
           maxDepth = options.maxDepth || -1,
           realDepth = 0;

       var ulS = $(content).find('ul');

       for (var x=0;x < ulS.length || realDepth == maxDepth;x++) {
         if ($(ulS[x]).parents(options.ul).length > realDepth) {
           realDepth = $(ulS[x]).parents(options.ul).length;
         }
       }

       this.options.realDepth = realDepth;

       // pre place containers for the menu
       for (var y=0;y <= realDepth;y++) {
         $(options.target).append(_this.menuContainers(y));
       }
     };

    /**
     * Binds open/close functionality to scrollBlocker and navbutton
     */
    this.bindopenClose = function() {

      var options = this.options,
          opts = {options: options},
          navButton = $(options.navButton),
          scrollBlocker = $(options.scrollBlocker);

      // Toggle nav show/hide on click
      navButton.click(options, this.openClose);

      // Close nav on page click
      scrollBlocker.click(options, this.openClose);
    };

    /**
     * If the drawer is open, this function will close it
     * If the drawer is closed, this function will open it
     */
    this.openClose = function(options) {
      var opts = _this.getOpts();

      var pageContainer = $(opts.pageContainer);

      // currently open .. close it
      if (_this.isDrawerOpen(pageContainer)) {
        _this.closeDrawer(opts);

        // reset the drawer to level 0 on close
        if (opts.resetOnClose) {
          _this.change('reset', opts.position);
        }
      }
      // currently closed .. open it
      else {
        _this.openDrawer(opts);
      }
    };

    /**
     * Helper function to determine if drawer is open or closed
     */
    this.isDrawerOpen = function(pageContainer) {
      if ($('html').hasClass('-js-menu-open')) {
        return true;
      }
      return false;
    };

    /**
     * Function for opening the drawer
     */
    this.openDrawer = function(options) {
      $('html').addClass('-js-menu-open');
      $(this.options.navButton).addClass('-js-menu-open');
    };

    /**
     * Function for closing the drawer
     */
    this.closeDrawer = function(options) {
      $('html').removeClass('-js-menu-open');
      $(this.options.navButton).removeClass('-js-menu-open');
    };

    /**
     * START HERE
     */
    this.init = function() {

      var origContent = this.getContent();

      // now manip the content
      var manipContent = this.options.contents(origContent);

      // add attribute data
      this.addDataAttr($(manipContent).find(this.options.li));

      // map the content
      var content = $(manipContent).find(this.options.ul).map(this.setup);

      // maxDepth set up work
      this.maxDepth(manipContent);

      // store the content for later
      this.options.content = content;
      this.options.manipContent = manipContent;

      // register all options to the target container
      this.reg(this.options);


      this.change(false, 0);
      this.bindopenClose();

      if (this.options.hideMenu) {
        $(this.menu).hide();
      }

      $(this.options.target).on('click', '[data-direction="prev"], [data-direction="next"]', function(event) {
        var opts = _this.getOpts();
        var level = 0;
        switch (event.target.dataset.direction) {
          case 'next':
            level = $(this)
              .parent()
              .attr(opts.data);
            break;

          case 'prev':
            var possibleLevel = $(opts.menu)
              .find('[' + opts.data + '="' + opts.level + '"]')
              .parent()
              .closest('[' + opts.data + ']')
              ;
            if (possibleLevel.length > 0) {
              level = possibleLevel
                .data(opts.data.replace('data-', ''));
            }
            else {
              level = 0;
            }

            break;
        }

        _this.change(event, level);
      });
    };

    /**
     * Initialze the setup
     */
    this.init();

  };

})(jQuery);
