export default () => {

  if ($('.figures-modal-slider').length) {
    const $figuresModalStatus = $('.figures-modal-status');
    const $figuresModalSlider = $('.figures-modal-slider');

    $figuresModalSlider.on('init reInit afterChange', function(event, slick, currentSlide) {
      const i = (currentSlide ? currentSlide : 0) + 1;
      $figuresModalStatus.text(i + '/' + slick.slideCount);
    });

    $figuresModalSlider.slick({
      dots: false,
      arrows: true,
      accessibility: true,
      draggable: false,
      swipe: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      centerMode: true,
      centerPadding: 0
    });
  }

  // open modal from the right sidebar
  $('.resource.modal-trigger.-figures').on('click', () => {
    $('.figures-modal-slider').slick('setPosition');
  });

  // open modal from clicking a table link in the body
  $('.modal-trigger.-modal-table').on('click', (e) => {

    // get table id and match it to table id in figure viewer.  get index of that slide and set slider to that slide
    const tableID = $(e.target).data('reference');
    const tableSlide = $('.figures-modal-slider .slick-slide:not(.slick-cloned)').find(`.figures-modal-slider-slide[data-table-id='${tableID}']`).parents('.slick-slide');
    const tableSlideIndex = tableSlide.data('slick-index');

    $('.figures-modal-slider').slick('setPosition');
    $('.figures-modal-slider').slick('slickGoTo', tableSlideIndex, true);

  });

};
