export default () => {

  $('.js-cme-disclosure-radios input[type="radio"]').change(() => {

    if ($('#cme-has-relationship').is(':checked')) {
      $('#cme-relationship-detail').prop('required', true);
    } else {
      $('#cme-relationship-detail').prop('required', false);
    }

  });

};
