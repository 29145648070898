import getOffset from '../../../js-plugins/get-offset';

export default () => {

  function isEmpty(el) {
    return !$.trim(el.html());
  }

  // Clone contents of sidebar into mobile toolbar tab so there isn't duplicated markup to manage
  // remove open/active classes since mobile version of expanders load as closed
  if ($('.practice-bulletin-sidebar-content').length) {

    if (!isEmpty($('.practice-bulletin-sidebar-content'))) {
      const $pbSidebarContent = $('.practice-bulletin-sidebar-content').clone(true, true);
      $('.practice-bulletin-mobile-sidebar-content').html($pbSidebarContent).find('.expander').removeClass('-start-open -active');
    } else {
      $('.article-detail-mobile-toolbar li.-resources').remove();
    }
  }

  // Reference locations scrolling behavior
  function scrollReferences(data) {

    $('.reference-scroll').removeClass('-active-reference');
    $('.practice-bulletin-references li').removeClass('-active-reference');

    const prefix = '#reference-',
          ref = data,
          target = prefix + ref,
          $target = $(target);

    if ($target.length) {

      const headerOffset = getOffset(),
            pos = $target.offset().top - headerOffset;

      $('html, body').stop().animate({
        'scrollTop': pos
      }, 500).promise().
        then(function() {
          $target.addClass('-active-reference');
        });
    }

  }

  // scroll to references section from individual reference
  $('.reference-scroll[ref-type!="table"]').on('click', (e) => {
    e.preventDefault();
    scrollReferences($(e.currentTarget).data('reference'));
  });

  // scroll to reference location from article locations link
  $('.article-location').on('click', (e) => {
    e.preventDefault();
    scrollReferences($(e.currentTarget).data('location'));
  });

};
